// commit dea8baf 2020-01-09

tooltips.init(); // enables tooltip support for footnotes and termrefs


const emailLinks = document.getElementsByClassName("email");
buildEmailLinks(emailLinks);

jQuery(document).ready(function($) {
  $('.copy-link').click(function () {
    $('.copy-link').removeClass('open');
    $(this).addClass('open');
    var getRemove = window.location.href.split('&');
    var hash1 = getRemove[0].split('#');
    console.log(hash1);
    var copyLink = hash1[0] + '#' + $(this).parent().attr('id');
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(copyLink).select();
    document.execCommand("copy");
    $temp.remove();
  });

  function scrollTo(id) {
    jQuery('body, html').stop(true, true).animate({
      scrollTop: jQuery(id).offset().top-$('.nav-second').outerHeight()
  }, 500);
  }

  // inter-page: run on page loaded - small delay to make sure everything is in place:
  if (window.location.hash) {
    setTimeout(function () {
      scrollTo(window.location.hash);
    }, 50);
  }

  // same-page: run on click:
  $('.sub-menu a[href*=\\#], p a[href*=\\#]').on('click', function (event) {
    // check if on same page:
    if (this.pathname === window.location.pathname) {
      event.preventDefault();
      scrollTo(this.hash);
    }
  });
});